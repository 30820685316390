import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { UserView, UserLang } from "./atom/atom";
import { useTranslation } from "react-i18next";

import { IconButton } from "@mui/material";
import UnderArrow from "./UnderArrow";
import useWindowDimensions from "./hooks/useWindowDimensions";

const NavBar = () => {
  const { t, i18n } = useTranslation("", { useSuspense: false });
  const { width } = useWindowDimensions();
  const [userView, setUserView] = useRecoilState(UserView);
  const [upBtnView, setUpBtnView] = useState(false);
  const [langBtnValue, setLangBtnValue] = useRecoilState(UserLang);
  const [langBtnToggle, setLangBtnToggle] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const body = document.getElementsByTagName("body")[0];

  useEffect(() => {
    i18n.changeLanguage(langBtnValue.value);
  }, [i18n, langBtnValue]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (window.scrollY === 0) {
        setUserView(null);
      }

      if (window.scrollY > 100) {
        setUpBtnView(true);
      } else {
        setUpBtnView(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width <= 450 && menuOpen) {
      body.style.overflow = "hidden";
    } else if (width <= 450 && !menuOpen) {
      body.style.overflow = "auto";
    }
  }, [menuOpen]);

  const findNodePositon = (id) => {
    const targetId = window.document.querySelector(id);

    if (targetId) {
      const rect = targetId.getBoundingClientRect();
      const y = rect.top + window.scrollY;
      return window.scrollTo(0, y - 100);
    }
    return;
  };

  const toggleLangBtn = () => {
    setLangBtnToggle((prev) => !prev);
  };

  const changeLang = (value) => {
    setLangBtnValue(value);
    toggleLangBtn();
  };

  return (
    <div className={`nav-bar-body${width > 450 ? "" : "-m"}`}>
      {width > 450 ? (
        <>
          <div className="nav-bar-left">
            <div>
              <img
                className="nav-bar-plant-module-logo"
                src={"/logo/plant-module-logo.png"}
                alt="plant-module-logo"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <div className="nav-bar-menu-box">
                <div
                  className={`plant-module-menu-normal-text ${
                    userView?.main === "company" ? "active" : "none"
                  } sub-menu`}
                >
                  <div onClick={() => findNodePositon("#company")}>
                    {t("Company")}
                  </div>
                  <UnderArrow fill="#344054" />

                  <div className="plant-module-sub-menu-box">
                    <div
                      className={`plant-module-sub-menu-text ${
                        userView?.sub === "about" ? "active" : "none"
                      }`}
                      onClick={() => findNodePositon("#about")}
                    >
                      {t("About Us")}
                    </div>
                    <div
                      className={`plant-module-sub-menu-text ${
                        userView?.sub === "workshop" ? "active" : "none"
                      }`}
                      onClick={() => findNodePositon("#workshop")}
                    >
                      {t("Workshop")}
                    </div>
                    {/* <div
                      className={`plant-module-sub-menu-text ${
                        userView?.sub === "organization" ? "active" : "none"
                      }`}
                      onClick={() => findNodePositon("#organization")}
                    >
                      {t("Organization")}
                    </div> */}
                  </div>
                </div>
                <div
                  className={`plant-module-menu-normal-text ${
                    userView?.main === "service" ? "active" : "none"
                  }`}
                  onClick={() => findNodePositon("#service")}
                >
                  {t("Service")}
                </div>
                <div
                  className={`plant-module-menu-normal-text ${
                    userView?.main === "achievement" ? "active" : "none"
                  }`}
                  onClick={() => findNodePositon("#achievement")}
                >
                  {t("Achievement")}
                </div>
              </div>
            </div>
          </div>
          <div className="nav-bar-right">
            <div
              className={`plant-module-menu-normal-text ${
                userView?.main === "contact" ? "active" : "none"
              }`}
              onClick={() => findNodePositon("#contact")}
            >
              {t("Contact")}
            </div>

            <div className="header-right-lang-dropdown-box">
              <div
                className="header-right-lang-dropdown"
                onClick={toggleLangBtn}
              >
                <div>{langBtnValue.label}</div> <UnderArrow fill="#344054" />
              </div>

              <div
                className={`header-right-lang-dropdown-menu-box ${
                  langBtnToggle ? "active" : "non-active"
                }`}
              >
                {langBtnValues.map((c) => {
                  const { value, label } = c;
                  return (
                    <div
                      key={value}
                      className={`header-right-lang-dropdown-menu${
                        langBtnValue.value === value ? " active" : ""
                      }`}
                      onClick={() => changeLang(c)}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="nav-bar-left-m">
            <img
              className="logo-plant-m"
              src={"/logo/logo-plant-m.png"}
              alt="logo-plant-img"
              style={{ width: "80px" }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <div className="nav-bar-right-m">
            <img
              className="menu-list-button-img"
              src={"/images/menu-list-button-img.png"}
              alt="menu-list-button-m"
              onClick={() => {
                setMenuOpen(true);
              }}
            />
          </div>
        </>
      )}
      <div className={`menu ${menuOpen ? "open" : ""}`}>
        <div className="menu-header">
          <img
            className="close-button"
            src={"/images/x_button.png"}
            alt="m-menu-x-button"
            onClick={() => {
              setMenuOpen(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="m-menu-detail">
          <div className="menu-item">
            <button
              className={`menu-item-button ${
                userView?.sub === "about" ? "active" : "none"
              }`}
              onClick={() => {
                setMenuOpen(false);
                findNodePositon("#about");
              }}
            >
              {t("About Us")}
            </button>
          </div>
          <div className="menu-item">
            <button
              className={`menu-item-button ${
                userView?.sub === "workshop" ? "active" : "none"
              }`}
              onClick={() => {
                setMenuOpen(false);
                findNodePositon("#workshop");
              }}
            >
              {t("Workshop")}
            </button>
          </div>
          {/* <div className="menu-item">
            <button
              className={`menu-item-button ${
                userView?.sub === "organization" ? "active" : "none"
              }`}
              onClick={() => {
                setMenuOpen(false);
                findNodePositon("#organization");
              }}
            >
              {t("Organization")}
            </button>
          </div> */}
          <div className="menu-item">
            <button
              className={`menu-item-button ${
                userView?.main === "service" ? "active" : "none"
              }`}
              onClick={() => {
                setMenuOpen(false);
                findNodePositon("#service");
              }}
            >
              {t("Service")}
            </button>
          </div>
          <div className="menu-item">
            <button
              className={`menu-item-button ${
                userView?.main === "achievement" ? "active" : "none"
              }`}
              onClick={() => {
                setMenuOpen(false);
                findNodePositon("#achievement");
              }}
            >
              {t("Achievement")}
            </button>
          </div>
          <div className="menu-item">
            <button
              className={`menu-item-button ${
                userView?.main === "contact" ? "active" : "none"
              }`}
              onClick={() => {
                setMenuOpen(false);
                findNodePositon("#contact");
              }}
            >
              {t("Contact")}
            </button>
          </div>
        </div>
        <div className="m-menu-lang">
          <div className="header-right-lang-dropdown-box">
            <div
              className="header-right-lang-dropdown"
              onClick={toggleLangBtn}
              style={{
                fontSize: menuOpen ? "1em" : "16px",
                width: menuOpen ? "68px" : "78px",
                padding: menuOpen ? "1px 10px 1px 15px" : "3px 12px",
                color: menuOpen ? "#475467" : "#101828",
              }}
            >
              <div>{langBtnValue.label}</div> <UnderArrow fill="#344054" />
            </div>

            <div
              className={`header-right-lang-dropdown-menu-box ${
                langBtnToggle ? "active" : "non-active"
              }`}
            >
              {langBtnValues.map((c) => {
                const { value, label } = c;
                return (
                  <div
                    key={value}
                    className={`header-right-lang-dropdown-menu${
                      langBtnValue.value === value ? " active" : ""
                    }`}
                    onClick={() => changeLang(c)}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="m-footer">
          {t("footer_text_1")}
          <br /> {t("footer_text_2")}
        </div>
      </div>
      {upBtnView && !menuOpen && (
        <IconButton
          sx={{
            position: "fixed",
            right: 20,
            bottom: 20,
            width: 50,
            height: 50,
          }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <img src="/images/page-up-btn.png" alt="btn" />
        </IconButton>
      )}
    </div>
  );
};

export default NavBar;

const langBtnValues = [
  { value: "en", label: "English" },
  { value: "th", label: "เกาหลี" },
];
