import React from "react";

const UnderArrow = ({ fill = "#00adad" }) => {
  return (
    <svg
      className="header-underArrow"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon" clipPath="url(#clip0_260_957)">
        <path
          id="Vector"
          d="M14.9858 7.33714C15.2469 7.59826 15.2469 8.01926 14.9858 8.28038L10.5573 12.7088C10.3495 12.9167 10.0138 12.9167 9.80594 12.7088L5.37748 8.28038C5.11636 8.01926 5.11636 7.59826 5.37748 7.33714C5.63861 7.07601 6.05961 7.07601 6.32073 7.33714L10.1843 11.1954L14.0479 7.33181C14.3037 7.07602 14.73 7.07601 14.9858 7.33714Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_260_957">
          <rect width="20" height="20" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnderArrow;
