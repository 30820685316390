import React, { useState, useMemo, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { UserLang } from "./atom/atom";
import Swal from "sweetalert2";
import useObserver from "./hooks/useObserver";
import { useTranslation } from "react-i18next";
import axios from "axios";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Contact = () => {
  const { t, i18n } = useTranslation("", { useSuspense: false });
  const userLang = useRecoilValue(UserLang);
  const [ref] = useObserver("contact");
  const { width } = useWindowDimensions();

  useEffect(() => {
    i18n.changeLanguage(userLang.value);
  }, [i18n, userLang]);

  const [inputValues, setInputValues] = useState({
    name: null,
    phone: null,
    company: null,
    email: null,
    contents: null,
  });

  const [dubleClick, setDubleClick] = useState(true);

  const handleInputValue = (e, field) => {
    setInputValues((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const btnDisabled = useMemo(() => {
    const { name, phone, email, contents } = inputValues;

    const check_target_list = [name, phone, email, contents];

    const check = check_target_list.every(
      (c) => c && c.replaceAll(" ", "").length > 0
    );

    if (!check) {
      return false;
    }

    return emailRegex.test(email);
  }, [inputValues]);

  const submitContact = async () => {
    setDubleClick(false);

    const alert = {
      title: t("contact_send_mail_success_msg_title"),
      text: t("contact_send_mail_success_msg_text"),
      icon: "success",
    };

    if (dubleClick) {
      try {
        const submitContactMail = await axios
          .post(`${process.env.REACT_APP_API_URI}/homepage/contact`, {
            ...inputValues,
          })
          .then((data) => data.data);

        if (!submitContactMail.success) {
          alert.title = t("contact_send_mail_fail_msg_title");
          alert.text = t("contact_send_mail_fail_msg_text");
          alert.icon = "error";
        }
      } catch (err) {
        alert.title = t("contact_send_mail_fail_msg_title");
        alert.text = t("contact_send_mail_fail_msg_text");
        alert.icon = "error";
      } finally {
        setDubleClick(true);
        setInputValues((prev) => ({
          ...prev,
          name: null,
          phone: null,
          company: null,
          email: null,
          contents: null,
        }));
        openAlert(alert);
      }
    }
  };

  return (
    <div
      ref={ref}
      id="contact"
      // className="plant-module-contents-body"
    >
      <div className={`plant-module-contact-body${width <= 450 ? "-m" : ""}`}>
        <div className="plant-module-contact-title">{t("Contact")}</div>
        <div className="contact">
          <div className="contact-top">
            <div className="contact-input-box">
              <div className="contact-title">
                {t("contact_name_input_title")}{" "}
                <div style={{ color: "#DC2626", marginLeft: "2px" }}>*</div>
              </div>
              <div
                className={`contact-input ${
                  inputValues.name === null
                    ? ""
                    : inputValues.name.length === 0
                    ? "input-error-active"
                    : ""
                }`}
              >
                <input
                  type="text"
                  required
                  placeholder={t("contact_name_input_placeholder")}
                  value={inputValues.name ?? ""}
                  onChange={(e) => handleInputValue(e, "name")}
                />
                <div className="contact-input-error">
                  {t("contact_name_input_error")}
                </div>
              </div>
            </div>
            <div className="contact-input-box">
              <div className="contact-title">
                {t("contact_phone_input_title")}{" "}
                <div style={{ color: "#DC2626", marginLeft: "2px" }}>*</div>
              </div>
              <div
                className={`contact-input ${
                  inputValues.phone === null
                    ? ""
                    : inputValues.phone.length === 0
                    ? "input-error-active"
                    : ""
                }`}
              >
                <input
                  type="text"
                  required
                  placeholder={t("contact_phone_input_placeholder")}
                  value={inputValues.phone ?? ""}
                  onChange={(e) => handleInputValue(e, "phone")}
                />
                <div className="contact-input-error">
                  {t("contact_phone_input_error")}
                </div>
              </div>
            </div>
            <div className="contact-input-box">
              <div className="contact-title">
                {t("contact_company_input_title")}
              </div>
              <div className="contact-input">
                <input
                  type="text"
                  placeholder={t("contact_company_input_placeholder")}
                  value={inputValues.company ?? ""}
                  onChange={(e) => handleInputValue(e, "company")}
                />
              </div>
            </div>
            <div className="contact-input-box">
              <div className="contact-title">
                {t("contact_email_input_title")}
                <div style={{ color: "#DC2626", marginLeft: "2px" }}>*</div>
              </div>
              <div
                className={`contact-input ${
                  inputValues.email === null
                    ? ""
                    : inputValues.email.length === 0 ||
                      !emailRegex.test(inputValues.email)
                    ? "input-error-active"
                    : ""
                }`}
              >
                <input
                  type="email"
                  required
                  placeholder="id@youremail.com"
                  value={inputValues.email ?? ""}
                  onChange={(e) => handleInputValue(e, "email")}
                />
                <div className="contact-input-error">
                  {t("contact_email_input_error")}
                </div>
              </div>
            </div>
          </div>
          <div className="contact-mid">
            <div className="contact-textarea-box">
              <div className="contact-title-box">
                <div className="contact-title">
                  {t("contact_contents_input_title")}
                  <div style={{ color: "#DC2626", marginLeft: "2px" }}>*</div>
                </div>
                <div className="contact-textarea-length">
                  {(inputValues.contents ?? "").length} / 800
                </div>
              </div>
              <div
                className={`contact-textarea ${
                  inputValues.contents === null
                    ? ""
                    : inputValues.contents.length === 0
                    ? "input-error-active"
                    : ""
                }`}
              >
                <textarea
                  placeholder={t("contact_contents_input_placeholder")}
                  value={inputValues.contents ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length > 800) {
                      alert(t("contact_contents_input_alert"));
                    } else {
                      handleInputValue(e, "contents");
                    }
                  }}
                />
                <div className="contact-input-error">
                  {t("contact_contents_input_error")}
                </div>
              </div>
            </div>
          </div>
          <div className="contact-bottom">
            <button onClick={submitContact} disabled={!btnDisabled}>
              {t("Contact")}
            </button>
            {/* <ReCAPTCHA
          sitekey="6LdY5nEfAAAAAFP8CjfO33IMqNpC0xlEqoVZ92hM"
          ref={recaptchaRef}
          onChange={checkReCAPTCHA}
        ></ReCAPTCHA> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

// const MySwal = withReactContent(Swal);

const openAlert = async ({ title, text, icon }) => {
  await Swal.fire({
    title,
    text,
    icon,
    timer: 5000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};
