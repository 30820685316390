import React from "react";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Clients = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      className="plant-module-clients-body"
      style={{
        height: width > 450 ? "220px" : "70px",
        marginTop: width > 450 ? "" : "30px",
        padding: width > 450 ? "0px 80px" : "0px 0px",
      }}
    >
      <img
        className="plant-module-clients-banner"
        alt="plant-module-clients-img"
        src={`/images/plant-module-clients-banner${
          width > 450 ? "" : "-m"
        }.png`}
      />
    </div>
  );
};

export default Clients;
