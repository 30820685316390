import React from "react";
import NavBar from "./NavBar";
import Company from "./Company";
import Service from "./Service";
import Achievement from "./Achievement";
import Clients from "./Clients";
import Contact from "./Contact";
import Footer from "./Footer";
import useObserver from "./hooks/useObserver";
import useWindowDimensions from "./hooks/useWindowDimensions";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { UserLang } from "./atom/atom";

const App = () => {
  const { width } = useWindowDimensions();
  const [ref] = useObserver("banner");
  const userLang = useRecoilValue(UserLang);

  const containerClass = classNames("plant-module", {
    "large-gap": width > 450,
    "small-gap": width <= 450,
  });

  const logoimgClass = classNames("plant-module-banner-logo-img", {
    "": width > 450,
    "small-gap": width <= 450,
  });

  return (
    <>
      <NavBar />
      <div className={containerClass} ref={ref}>
        <div className="plant-module-banner">
          <img
            className="plant-module-banner-img"
            src={"/images/plant-module-banner.png"}
            alt="plant-module"
          />
          <img
            className={logoimgClass}
            src={"/images/plant-module-banner-logo.png"}
            alt="plant-module"
          />
        </div>
        <Company userLang={userLang.value} />
        <Service userLang={userLang.value} />
        <Achievement userLang={userLang.value} />
        <Contact />
        <Clients />
      </div>
      <Footer />
    </>
  );
};

export default App;
