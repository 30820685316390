import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSetRecoilState } from "recoil";
import { UserView } from "../atom/atom";
import useWindowDimensions from "./useWindowDimensions";

const useObserver = (id) => {
  const { width, height } = useWindowDimensions();

  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin:
      width > 450
        ? `-400px 0px ${-height + 400}px 0px`
        : `-200px 0px ${-height + 200}px 0px`,
    triggerOnce: false,
  });

  const setUserView = useSetRecoilState(UserView);

  useEffect(() => {
    if (inView) {
      if (sub_menu_list.includes(id)) {
        setUserView({
          main: "company",
          sub: id,
        });
      } else {
        setUserView({
          main: id,
          sub: null,
        });
      }
    }
    // eslint-disable-next-line
  }, [inView, id]);

  return [ref, inView];
};

export default useObserver;

const sub_menu_list = ["about", "workshop", "organization"];
