import React from "react";

import useObserver from "./hooks/useObserver";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Company = ({ userLang }) => {
  const { width } = useWindowDimensions();
  const [ref] = useObserver("company");
  const [ref1] = useObserver("about");
  const [ref2] = useObserver("workshop");
  const [ref3] = useObserver("organization");

  return (
    <div ref={ref} id="company" className="plant-module-contents-body">
      <img
        ref={ref1}
        id="about"
        className="plant-module-contents-main-img"
        alt="plant-module-about"
        src={`/images/${userLang}/plant-module-about${
          width > 450 ? "" : "-m"
        }.png`}
        style={{
          marginBottom: width > 450 ? "" : "40px",
        }}
      />
      <img
        ref={ref2}
        id="workshop"
        className="plant-module-contents-main-img"
        alt="plant-module-workshop"
        src={`/images/${userLang}/plant-module-workshop${
          width > 450 ? "" : "-m"
        }.png`}
        style={{
          marginBottom: width > 450 ? "" : "40px",
        }}
      />
      {/* <img
        ref={ref3}
        id="organization"
        className="plant-module-contents-main-img"
        alt="plant-module-organization"
        src={`/images/${userLang}/plant-module-organization${
          width > 450 ? "" : "-m"
        }.png`}
      /> */}
    </div>
  );
};

export default Company;
