import React from "react";

import useObserver from "./hooks/useObserver";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Achievement = ({ userLang }) => {
  const { width } = useWindowDimensions();
  const [ref] = useObserver("achievement");
  return (
    <div ref={ref} id="achievement" className="plant-module-contents-body">
      <img
        className="plant-module-contents-main-img"
        alt="plant-module-company"
        src={`/images/${userLang}/plant-module-experience${
          width > 450 ? "" : "-m"
        }.png`}
      />
    </div>
  );
};

export default Achievement;
