import React from "react";
import useObserver from "./hooks/useObserver";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Service = ({ userLang }) => {
  const { width } = useWindowDimensions();
  const [ref] = useObserver("service");
  return (
    <div
      ref={ref}
      id="service"
      className="plant-module-contents-body"
      style={{
        padding: width > 450 ? "" : "0px",
      }}
    >
      <img
        className="plant-module-contents-main-img"
        alt="plant-module-company"
        src={`/images/${userLang}/plant-module-services${
          width > 450 ? "" : "-m"
        }.png`}
      />
    </div>
  );
};

export default Service;
