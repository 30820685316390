import React, { useEffect } from "react";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { UserLang } from "./atom/atom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { width } = useWindowDimensions();
  const { t, i18n } = useTranslation("", { useSuspense: false });
  const userLang = useRecoilValue(UserLang);

  useEffect(() => {
    i18n.changeLanguage(userLang.value);
  }, [i18n, userLang]);

  return (
    <div>
      <div className={`footer-body${width > 450 ? "" : "-m"}`}>
        {/* <div className="footer-top">
              <div className="footer-text">Terms</div>
              <div className="footer-vertical-bar" />
              <div className="footer-text">Plans</div>
              <div className="footer-vertical-bar" />
              <div className="footer-text">Contact Us</div>
            </div> */}
        <div className={`footer-bottom${width > 450 ? "" : "-m"}`}>
          {t("footer_text_1")}
          <br />
          {t("footer_text_2")}
        </div>
      </div>
    </div>
  );
};

export default Footer;
