import { atom } from "recoil";

export const UserLang = atom({
  key: "UserLang",
  default: { value: "en", label: "English" },
});

export const UserView = atom({
  key: "UserView",
  default: null,
});
